// Navigation
const nav = document.querySelector('.header__nav');
const burgerButton = document.querySelector('.menu__control');
const burgerIcon = document.querySelector('.menu__control img');
const header = document.querySelector('.header');
const overlay = document.querySelector('.overlay');
const headerLogo = document.querySelector('.header__logo-link--lg');
const navLinks = document.querySelectorAll('.nav__link');
const primaryLinks = document.querySelectorAll('.nav__link--primary > a');
const secondaryContainer = document.querySelectorAll('.nav__list--secondary-container');
const tertiaryContainer = document.querySelectorAll('.nav__list--tertiary-container');
const doc = document.documentElement;
const floatingButtonContainer = document.querySelector('.floating-button__container');

// Show nav states
function showNav() {
	doc.classList.add('noscroll');
	overlay.classList.add('overlay--show');
	burgerIcon.classList.remove('fa-bars');
	burgerIcon.classList.add('fa-times');
	burgerIcon.src = '/assets/icons/times.svg';
	header.classList.add('header--open');
	nav.classList.add('header__nav--active');
	headerLogo.classList.add('header__logo-link--hide');
}

// Hide nav states
function hideNav() {
	doc.classList.remove('noscroll');
	overlay.classList.remove('overlay--show');
	burgerIcon.classList.add('fa-bars');
	burgerIcon.classList.remove('fa-times');
	burgerIcon.src = '/assets/icons/bars.svg';
	header.classList.remove('header--open');
	nav.classList.remove('header__nav--active');
	headerLogo.classList.remove('header__logo-link--hide');
	// Remove active class from any active links
	removeActiveClass();
}

// Open secondary header nav
function showSecondary(e) {
	const currentTarget = e.target;
	const currentSecondaryContainer = currentTarget.nextElementSibling;
	const closeNav = document.querySelectorAll('.nav-close');

	// Add active class to current link
	currentTarget.classList.add('active');

	// Hide all secondary lists
	for (let i = 0; i < secondaryContainer.length; i++) {
		secondaryContainer[i].classList.remove('secondary--visible');
	}

	// Close secondary lists by clicking 'x'
	if (closeNav.length > 0) {
		for (let j = 0; j < closeNav.length; j++) {
			closeNav[j].addEventListener('click', function() {
				currentSecondaryContainer.classList.remove('secondary--visible');
				// Remove active class from any active links
				removeActiveClass();
			});
		}
	}

	// Only run function when you click 'parent' version of primary link
	if (currentSecondaryContainer) {
		const secondaryInner = currentSecondaryContainer.querySelector('.nav__list--secondary-inner');
		const listHeight = secondaryInner.querySelector('.nav__list--full-height');
		currentSecondaryContainer.style.display = 'block'; // Make it visible
		const height = listHeight.scrollHeight + 1 + 'px'; // Get its height
		currentSecondaryContainer.style.display = ''; //  Hide it again
		currentSecondaryContainer.classList.add('secondary--visible');
		const w = document.documentElement.clientWidth;

		if (w >= 1024) {
			currentSecondaryContainer.style.height = height; // Update the max-height
			secondaryInner.style.height = height;
			listHeight.style.position = 'absolute';
		}

		// Once the transition is complete, remove the inline max-height so the content can scale responsively
		window.setTimeout(function() {
			currentSecondaryContainer.style.height = '';
		}, 10);

		// Close secondary container if you click on the page outside it
		window.addEventListener('click', function(e) {
			if (!currentSecondaryContainer.contains(e.target) && (!header.contains(e.target))) {
				// Remove active class from any active links
				removeActiveClass();
				currentSecondaryContainer.classList.remove('secondary--visible');
			}
		});
	}
}

// Defining toggle function
function toggleNav() {
	if (burgerIcon.classList.contains('fa-bars')) {
		showNav();
	} else {
		hideNav();
	}
}

// On resize
// Defining event listener function
function screenResize() {
	const w = document.documentElement.clientWidth;

	if (w >= 1024) {
		hideNav();
		for (let i = 0; i < tertiaryContainer.length; i++) {
			tertiaryContainer[i].style.maxHeight = 'none';
		}
	} else {
		for (let j = 0; j < tertiaryContainer.length; j++) {
			tertiaryContainer[j].style.maxHeight = 0;
		}
	}
}

// Remove active class from any active links
function removeActiveClass() {
	const activeLinks = header.querySelectorAll('.active');
	for (let i = 0; i < activeLinks.length; i++) {
		activeLinks[i].classList.remove('active');
	}
	// Close all accordions
	const w = document.documentElement.clientWidth;

	if (w < 1024) {
		for (let j = 0; j < tertiaryContainer.length; j++) {
			tertiaryContainer[j].style.maxHeight = 0;
		}
	}
}

if (nav) {
	// Calling the function after click event occurs
	burgerButton.addEventListener('click', function(e) {
		e.preventDefault();
		toggleNav();
	});

	overlay.addEventListener('click', function() {
		toggleNav();
	});

	// Show secondary nav
	let i;
	for (i = 0; i < primaryLinks.length; i++) {
		primaryLinks[i].addEventListener('click', function(e) {
			e.preventDefault();
			// Remove active class from other primary links
			removeActiveClass();
			showSecondary(e);
		});
	}

	// GTM navigation tracking
	let j;
	for (j = 0; j < navLinks.length; j++) {
		navLinks[j].addEventListener('click', function(e) {
			e.stopPropagation();
			let linkTitle = this.classList.contains('nav__link--secondary') ? this.querySelector('.nav__link-title').innerText : this.querySelector('a').innerText;
			const linkParent = this.parentNode;

			// Get link title at right depth
			if (this.classList.contains('nav__link--secondary')) {
				// Secondary links
				const primaryTitle = linkParent.querySelector('.nav__link--primary > a').innerText;
				linkTitle = primaryTitle + ' | ' + linkTitle;
			} else if (this.classList.contains('nav__link--tertiary')) {
				// Tertiary links
				const primaryLabel = linkParent.parentNode.parentNode.parentNode.querySelector('.nav__link--primary > a').innerText;
				const secondaryTitle = linkParent.parentNode.parentNode.querySelector('.nav__link-title').innerText;

				linkTitle = primaryLabel + ' | ' + secondaryTitle + ' | ' + linkTitle;
			}

			// Clean linkTitle letiable
			linkTitle = linkTitle.replace(/\n/g, '');

			/* eslint-disable */
			if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
				// Push link title to datalayer
				dataLayer.push({
				  'event': 'gen_ev_navigation',
				  'event_category': 'navigation',
				  'event_action': 'main menu',
				  'event_label': linkTitle,
				  'event_value': 0,
				  'non_interaction': false
				});
			}
			/* eslint-enable */
		});
	}

	let lastScrollTop = window.pageYOffset;

	// Hide menu when you've scrolled 1/3 of the way down the page - if nav does not contain 'header--sticky' class
	window.onscroll = function(ev) {
		const st = window.pageYOffset || document.documentElement.scrollTop;

		if (st > lastScrollTop) {
			if ((window.pageYOffset) >= document.body.offsetHeight / 3) {
				// Mobile and tablet
				if (window.innerWidth < 1024) {
					header.style.display = 'none';
				} else if (window.innerWidth >= 1024 && floatingButtonContainer != null) {
					// Desktop
					nav.classList.add('header__nav--scrolled-up');
					floatingButtonContainer.classList.add('floating-button__container--visible');
				} else {
					header.style.display = 'none';
				}

				// Hide all secondary lists
				for (let i = 0; i < secondaryContainer.length; i++) {
					secondaryContainer[i].classList.remove('secondary--visible');
				}
				// Remove active class from any active links
				removeActiveClass();
			} else {
				header.style.display = 'flex';
			}
		} else {
			header.style.display = 'flex';
		}

		lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
	};

	// Attaching the event listener function to window's resize event
	window.addEventListener('resize', screenResize);
}
