const rangeslider = document.getElementById('range-slider');
if (rangeslider != null) {
	rangeslider.addEventListener('change', function() {
		document.getElementById('sliderdonation-amount').innerHTML = this.value;
		const x = rangeslider.value;
		const color = 'linear-gradient(90deg, #e7600c ' + x + '%,' + '#e6e6e6 ' + x + '%)';
		rangeslider.style.background = color;
		const watertablets = document.getElementById('water-tablets');
		watertablets.innerHTML = Math.round(this.value / 5 * 100 / 100);
		const birthkit = document.getElementById('birth-kit');
		birthkit.innerHTML = Math.round(this.value / 3 * 100 / 100);
		const foodsachets = document.getElementById('food-sachets');
		foodsachets.innerHTML = Math.round(this.value / 2 * 100 / 100);
		const hygienekit = document.getElementById('hygiene-kits');
		hygienekit.innerHTML = Math.round(this.value / 3.5 * 100 / 100);
		const schoolbag = document.getElementById('school-bag');
		schoolbag.innerHTML = Math.round(this.value / 4.5 * 100 / 100);
	});
}
