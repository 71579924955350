// How long you want the animation to take, in ms
const animationDuration = 1000;
// Calculate how long each ‘frame’ should last
const frameDuration = 1000 / 60;
// Calculate how many frames we need to complete the animation
const totalFrames = Math.round(animationDuration / frameDuration);
// An ease-out function that slows the count as it progresses
const easeOutQuad = t => t * (2 - t);

// The animation function, which takes an Element
const animateCountUp = el => {
	let frame = 0;
	const countTo = parseInt(el.innerHTML, 10);
	// Start the animation running
	const counter = setInterval(() => {
		frame++;

		const progress = easeOutQuad(frame / totalFrames);
		// Use the progress value to calculate the current count
		const currentCount = Math.round(countTo * progress);
		// If the current count has changed, update the element
		if (parseInt(el.innerHTML, 10) !== currentCount) {
			el.innerHTML = currentCount;
		}
		// If we’ve reached our last frame, stop the animation
		if (frame === totalFrames) {
			clearInterval(counter);
		}
	}, frameDuration);
};

// Run the animation on all elements with a class of ‘countup’
const countupEls = document.querySelectorAll('.countup');

if (countupEls.length > 0) {
	const countContainer = document.querySelector('.accountability');

	/* eslint-disable */
	var countObserver = new IntersectionObserver(function(entries) {
		if (entries[0].isIntersecting === true) {
			countupEls.forEach(animateCountUp);
		}
	}, { threshold: [0.3] });

	countObserver.observe(countContainer);
	/* eslint-enable */
}
