// Floating button functionality
/* eslint-disable */
const floatingButton = document.querySelector('.floating-button__container');
const headerNav = document.querySelector('.header__nav');
const primaryCTAButton = document.querySelector('.header > .header__utility .header__primary-cta');
const navPrimaryCTA = document.querySelector('.nav__list .header__primary-cta');
const searchButton = document.querySelector('.header > .header__utility');

const showFloatingButton = () => {
	// Hide CTA button if floating button exists
	if (floatingButton != null && primaryCTAButton != null && navPrimaryCTA!= null) {
		primaryCTAButton.classList.add('header__primary-cta--hidden');
		navPrimaryCTA.classList.add('header__primary-cta--hidden');
	}
	// If floating button exists on the page and you're on mobile/tablet view
	if (floatingButton != null && window.innerWidth < 1024) {
		window.addEventListener('scroll', function() {
			// If the user has scrolled past the height of the initial viewport
			if (window.pageYOffset > window.innerHeight) {
				floatingButton.classList.add('floating-button__container--visible');
			} else {
				floatingButton.classList.remove('floating-button__container--visible');
			}
		});
	} else {
		// Desktop behaviour
		window.addEventListener('scroll', function() {
			// If the user has scrolled past the height of the initial viewport
			if (window.pageYOffset > window.innerHeight) {
				if (floatingButton != null) {
					if (!floatingButton.classList.contains('floating-button__container--visible')) {
						floatingButton.classList.add('floating-button__container--visible');
					}
					if (headerNav != null) {
						headerNav.classList.add('header__nav--scrolled-up');
					}
				}
			} else {
				if (floatingButton != null) {
					if (floatingButton.classList.contains('floating-button__container--visible')) {
						floatingButton.classList.remove('floating-button__container--visible');
					}
					if (headerNav != null) {
						headerNav.classList.remove('header__nav--scrolled-up');
					}
				}
			}
		});
	}
}

showFloatingButton();

// Fire event on window resize
if (floatingButton != null) {
	window.addEventListener('resize', showFloatingButton);
}

/* eslint-enable */
