const videoplayer = document.querySelectorAll('.img-and-video-container');
if (videoplayer.length > 0) {
	let i;
	for (i = 0; i < videoplayer.length; i++) {
		// play video on image hover for desktop
		const playOnHover = videoplayer[i].querySelectorAll('.play-video');
		videoplayer[i].addEventListener('mouseover', function() {
			if (playOnHover.length > 0) {
				let i;
				for (i = 0; i < playOnHover.length; i++) {
					playOnHover[i].play();
					playOnHover[i].classList.remove('hide');
				}
			}
		});

		// pause video on mouse leave for desktop
		videoplayer[i].addEventListener('mouseleave', function() {
			if (playOnHover.length > 0) {
				let i;
				for (i = 0; i < playOnHover.length; i++) {
					playOnHover[i].pause();
					playOnHover[i].classList.add('hide');
				}
			}
		});
	}
}

const closeFilter = document.querySelectorAll('.randomchild_Filters .lightbox-close');
const randomchildLightbox = document.querySelectorAll('.randomchild_Filters .lightbox');
const randomchildFilterButton = document.querySelectorAll('.randomchild_Filters .filterButton');
const randomchildOverlay = document.querySelectorAll('.randomchild_Filters .overlay--lightbox');
if (randomchildFilterButton.length > 0) {
	let i;
	for (i = 0; i < randomchildFilterButton.length; i++) {
		// Display filter popup onclick filter button
		randomchildFilterButton[i].addEventListener('click', function(e) {
			if (randomchildLightbox.length > 0) {
				let i;
				for (i = 0; i < randomchildLightbox.length; i++) {
					randomchildLightbox[i].classList.add('lightbox--visible');
					if (randomchildOverlay.length > 0) {
						let i;
						for (i = 0; i < randomchildOverlay.length; i++) {
							randomchildOverlay[i].classList.add('overlay--lightbox--visible');
						}
					}
				}
			}
		});
	}
}
// Remove filter popup onclick close button
if (closeFilter.length > 0) {
	let i;
	for (i = 0; i < closeFilter.length; i++) {
		closeFilter[i].addEventListener('click', function(e) {
			if (randomchildLightbox.length > 0) {
				let i;
				for (i = 0; i < randomchildLightbox.length; i++) {
					randomchildLightbox[i].classList.remove('lightbox--visible');
					if (randomchildOverlay.length > 0) {
						let i;
						for (i = 0; i < randomchildOverlay.length; i++) {
							randomchildOverlay[i].classList.remove('overlay--lightbox--visible');
						}
					}
				}
			}
		});
	}
}

const sponsormultiplechildren = document.getElementById('sponsor-multiple-children');
const multiplechildrennotificationbanner = document.getElementById('multiplechildren-notification-banner');

if (sponsormultiplechildren != null && multiplechildrennotificationbanner != null) {
	sponsormultiplechildren.onclick = function(e) {
		multiplechildrennotificationbanner.parentNode.classList.add('show');
		e.preventDefault();
	};
}

const birthmonthcontainer = document.getElementById('Birthday');
const birthmonthwrappercontainer = document.getElementById('birthmonthwrapper-banner');

if (birthmonthcontainer != null && birthmonthwrappercontainer != null) {
	birthmonthcontainer.onclick = function(e) {
		birthmonthwrappercontainer.parentNode.classList.add('show');
		e.preventDefault();
	};
}
