// Styling classes for text fields
const textFields = document.querySelectorAll('.text-field');

// Style pre-filled inputs
if (textFields.length > 0) {
	// Loop through each text field
	let i;
	for (i = 0; i < textFields.length; i++) {
		const thisInput = textFields[i].getElementsByTagName('input')[0];

		// if it is populated, add class
		if (thisInput.value.length > 0) {
			textFields[i].classList.add('text-field--populated');
		}

		thisInput.addEventListener('blur', (event) => {
			// if it is populated, add class
			if (thisInput.value.length > 0) {
				thisInput.closest('.text-field').classList.add('text-field--populated');
			} else {
				thisInput.closest('.text-field').classList.remove('text-field--populated');
			}
		});

		// Calculate padding on currency inputs to allow space around currency character
		if (textFields[i].classList.contains('text-field--currency')) {
			// calculate width of icon element
			const iconEle = textFields[i].querySelector('.text-field__icon');
			const iconInfo = iconEle.getBoundingClientRect();
			let iconWidth = iconInfo.width;

			iconWidth = Math.floor(iconWidth + 20);

			// Set the padding on the manual input to accommodate for the character's width
			const manualInput = textFields[i].querySelector('.js-product-config-manual');
			manualInput.style.paddingLeft = iconWidth + 'px';
		}
	}
}
