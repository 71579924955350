document.addEventListener('DOMContentLoaded', function() {
	let lazyloadImages;
	let i;
	const lazyVideos = [].slice.call(document.querySelectorAll('video.lazy-video'));
	/*eslint-disable */
	if ('IntersectionObserver' in window) {
		/* lazy load images */
		lazyloadImages = document.querySelectorAll('.lazy');
		const imageObserver = new IntersectionObserver(function(entries, observer) {
			for (i = 0; i < entries.length; ++i) {
				if (entries[i].isIntersecting) {
					const image = entries[i].target;
					image.src = image.dataset.src;
					if (image.dataset.srcset) {
						image.srcset = image.dataset.srcset;
					}
					image.classList.remove('lazy');
					imageObserver.unobserve(image);
				}
			}
		});

		for (i = 0; i < lazyloadImages.length; ++i) {
			imageObserver.observe(lazyloadImages[i]);
		}
		/* lazy load video with autoplay*/
		const lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
			entries.forEach(function (video) {
				if (video.isIntersecting) {
					for (const source in video.target.children) {
						const videoSource = video.target.children[source];
						if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
							videoSource.src = videoSource.dataset.src;
						}
					}

					video.target.load();
					video.target.classList.remove('lazy-video');
					lazyVideoObserver.unobserve(video.target);
				}
			});
		});

		lazyVideos.forEach(function (lazyVideo) {
			lazyVideoObserver.observe(lazyVideo);
		});
	} else {
		let lazyloadThrottleTimeout;
		lazyloadImages = document.querySelectorAll('.lazy');

		function lazyload() {
			if (lazyloadThrottleTimeout) {
				clearTimeout(lazyloadThrottleTimeout);
			}

			lazyloadThrottleTimeout = setTimeout(function() {
				const scrollTop = window.pageYOffset;
				for (i = 0; i < lazyloadImages.length; ++i) {
					if (lazyloadImages[i].offsetTop < (window.innerHeight + scrollTop)) {
						lazyloadImages[i].src = lazyloadImages[i].dataset.src;
						if (lazyloadImages[i].dataset.srcset) {
							lazyloadImages[i].srcset = lazyloadImages[i].dataset.srcset;
						}
						lazyloadImages[i].classList.remove('lazy');
					}
				}
				if (lazyloadImages.length === 0) {
					document.removeEventListener('scroll', lazyload);
					window.removeEventListener('resize', lazyload);
					window.removeEventListener('orientationChange', lazyload);
				}
			}, 20);
		}

		document.addEventListener('scroll', lazyload, {passive: true});
		window.addEventListener('resize', lazyload);
		window.addEventListener('orientationChange', lazyload);
	}
});
