const searchButton = document.getElementById('search_icon');
const mobileSearchButton = document.getElementById('mobile_search_icon');
const searchnotification = document.getElementById('searchnotification-banner');
const searchMobileTextBox = document.getElementById('mobile_search-inputbox');

if (searchButton != null && mobileSearchButton != null) {
	searchButton.onclick = function(e) {
		searchnotification.parentNode.classList.add('show');
		e.preventDefault();
		// DataLayer push to track behaviour of search button
		// Desktop Search button
		/* eslint-disable */
		if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
			dataLayer.push({
				'event': 'gen_ev_navigation',
				'event_category': 'navigation',
				'event_action': 'site search stub test',
				'event_label': 'desktop site search'
			})
		}
		/* eslint-enable */
	};
	mobileSearchButton.onclick = function(e) {
		searchnotification.parentNode.classList.add('show');
		searchMobileTextBox.value = '';
		e.preventDefault();
		// DataLayer push to track behaviour of search button
		// Mobile Search button
		/* eslint-disable */
		if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
			dataLayer.push({
				'event': 'gen_ev_navigation',
				'event_category': 'navigation',
				'event_action': 'site search stub test',
				'event_label': 'mobile site search'
			})
		}
		/* eslint-enable */
	};
}
