// Accordion
const acc = document.getElementsByClassName('list--accordion');

if (acc.length > 0) {
	let i;
	for (i = 0; i < acc.length; i++) {
		const accPanel = acc[i].querySelectorAll('button.list__item-header');
		let j;
		for (j = 0; j < accPanel.length; j++) {
			// close all items on load if close-on-load attibute is true
			const firstPanel = accPanel[0];
			const firstPanelSibling = firstPanel.nextElementSibling;

			if (!acc[i].dataset.closeOnLoad === true) {
				firstPanel.classList.add('active');
				firstPanel.setAttribute('aria-expanded', 'true');
				firstPanelSibling.style.maxHeight = firstPanelSibling.scrollHeight + 16 + 'px';
			}

			// Panel click event
			accPanel[j].addEventListener('click', function(e) {
				e.preventDefault();

				const currentAcc = this.closest('.list--accordion');
				const activePanelLinks = currentAcc.querySelectorAll('.active');
				const panel = this.nextElementSibling;

				const allPanels = currentAcc.querySelectorAll('.list__item-body');

				// Allow multiple panels to be open at once if the multipleActive data attribute is true
				if (!currentAcc.dataset.multipleActive === true) {
					// Close other active panels so only one is open at a time
					// Remove active class
					let k;
					for (k = 0; k < activePanelLinks.length; k++) {
						activePanelLinks[k].classList.remove('active');
						activePanelLinks[k].setAttribute('aria-expanded', 'false');
					}
					// Close all panels
					let j;
					for (j = 0; j < allPanels.length; j++) {
						allPanels[j].style.maxHeight = null;
					}
				}

				// Toggle between adding and removing the 'active' class
				if (this.classList.contains('active')) {
					this.classList.remove('active');
					this.setAttribute('aria-expanded', 'false');
				} else {
					this.classList.add('active');
					this.setAttribute('aria-expanded', 'true');
				}

				// Toggle between hiding and showing the active panel
				if (parseInt(panel.style.maxHeight, 10) > 0) {
					panel.style.maxHeight = null;
				} else {
					panel.style.maxHeight = panel.scrollHeight + 16 + 'px';
				}

				/* eslint-disable */
				if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined' && !this.classList.contains('nav__link--accordion')) {
					const panelTitle = this.querySelector('.subtitle').innerText;
					// GTM tracking event
					dataLayer.push({
					  'event': 'gen_ev_faq',
					  'event_category': 'faq',
					  'event_action': 'maximize / minimize',
					  'event_label': panelTitle,
					  'event_value': 0,
					  'non_interaction': false
					});
				}
				/* eslint-enable */
			});
		}
		const accPanelV2 = acc[i].querySelectorAll('.list--accordion-v2 button.list__item-header');
		let k;
		for (k = 0; k < accPanelV2.length; k++) {
			// close all items on load if close-on-load attibute is true
			const firstPanelV2 = accPanelV2[0];
			const firstPanelSiblingV2 = firstPanelV2.nextElementSibling;

			if (!acc[i].dataset.closeOnLoad === true) {
				if (firstPanelV2.classList.contains('active')) {
					firstPanelV2.classList.remove('active');
					firstPanelV2.setAttribute('aria-expanded', 'false');
					firstPanelSiblingV2.style.maxHeight = '';
				}
			}
		}
	}
}
