// Changing tabs hide/show content
const openError = document.querySelector('.payment-notification.notification-banner');

// Tab bar with pure Javascript
const tabs = document.querySelectorAll('[data-tab-value]');
const tabInfos = document.querySelectorAll('[data-tab-info]');
const tabCheck = document.getElementById('tabs');

if (tabCheck != null) {
	tabs.forEach((tab, index) => {
		// Load first available tab on initial page load
		if (index === 0) {
			tab.classList.add('tab-active');

			const thisTabTarget = tab.dataset.tabValue;
			document.querySelector(thisTabTarget).classList.add('active');
		}

		tab.addEventListener('click', () => {
			tab.classList.add('tab-active');
			const getSiblings = function(e) {
				// for collecting siblings
				const siblings = [];
				// if no parent, return no sibling
				if (!e.parentNode) {
					return siblings;
				}
				// first child of the parent node
				let sibling = e.parentNode.firstChild;
				// collecting siblings
				while (sibling) {
					if (sibling.nodeType === 1 && sibling !== e) {
						siblings.push(sibling);
					}
					sibling = sibling.nextSibling;
				}
				return siblings;
			};

			const siblings = getSiblings(tab);
			siblings.map(e => e.classList.remove('tab-active'));
			const target = document.querySelector(tab.dataset.tabValue);

			tabInfos.forEach(tabInfo => {
				tabInfo.classList.remove('active');
			});
			target.classList.add('active');
		});
	});

	// scroll tab bar If there are more tabs
	const tabsContent = document.querySelectorAll('.tabs .tabs-content');
	const tabsCount = document.querySelectorAll('.tabs .tabs-content').length;
	const visibletabs = tabsCount * 110;
	const tabBlockWidth = document.getElementById('tabs').offsetWidth;
	if (visibletabs > tabBlockWidth) {
		const noOfVisibleItems = Math.floor(tabBlockWidth / 120);
		if (tabsContent.length > 0) {
			let i;
			for (i = noOfVisibleItems; i < tabsContent.length; i++) {
				tabsContent[i].classList.add('scrollTab');
			}
		}
		const scrollTabs = document.querySelectorAll('.scrollTab');
		const tabContentWidth = document.querySelector('.tabs .tabs-content').offsetWidth;
		const list = [...document.querySelectorAll('.tabs .tabs-content')];
		const active = document.querySelector('.tabs .tabs-content.tab-active');
		let activeIndex = list.indexOf(active);
		if (scrollTabs != null) {
			scrollTabs.forEach(scrollTab => {
				scrollTab.addEventListener('click', () => {
					if (tabsContent.length > 0) {
						let i;
						for (i = 0; i < tabsContent.length; i++) {
							if (!tabsContent[i].classList.contains('scrollTab')) {
								tabsContent[i].classList.add('scrollTab');
								const scrollTabs = document.querySelectorAll('.scrollTab');
								if (scrollTabs != null) {
									scrollTabs.forEach(scrollTab => {
										scrollTab.addEventListener('click', () => {
											const clickedIndex = list.indexOf(scrollTab);
											const parentTabBar = scrollTab.parentNode;
											if (clickedIndex > activeIndex) {
												parentTabBar.scrollLeft += tabContentWidth;
											} else if (activeIndex > clickedIndex) {
												parentTabBar.scrollLeft -= tabContentWidth;
											}
											activeIndex = clickedIndex;
											return activeIndex;
										});
									});
								}
							}
						}
					}
					const clickedIndex = list.indexOf(scrollTab);
					const parentTabBar = scrollTab.parentNode;
					if (clickedIndex > activeIndex) {
						parentTabBar.scrollLeft += tabContentWidth;
					} else if (activeIndex > clickedIndex) {
						parentTabBar.scrollLeft -= tabContentWidth;
					}
					activeIndex = clickedIndex;
					return activeIndex;
				});
			});
		}
	}

	// If error dialog is showing on payment details form, show the tab it's on by default
	if (openError) {
		const listOfTabContents = document.querySelectorAll('.tab-content .tabs__tab');
		const listOfTabLabels = document.querySelectorAll('.tabs .tabs-content');
		let errorID = openError.id;
		errorID = errorID.replace('-Dialog', '');

		const errorTabContent = document.getElementById(errorID);
		const errorclickableTab = document.querySelector('[data-tab-value="#' + errorID + '"]');

		// Remove any active state from tabs
		let i;
		for (i = 0; i < listOfTabContents.length; i++) {
			listOfTabContents[i].classList.remove('active');
			listOfTabLabels[i].classList.remove('tab-active');
		}

		// Add active state to tab with associated error message open
		errorclickableTab.classList.add('tab-active');
		errorTabContent.classList.add('active');
	}
}
