// Set cookie function
function setCookie(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	const expires = 'expires=' + d.toUTCString();
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function getCookie(cname) {
	const name = cname + '=';
	const ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

function optimizelySendHoldEvent() {
	/* eslint-disable */
	const optimizelyAllowDataSharing = getCookie('optimizelyAllowDataSharing') ? true : false;
	const optimizelyEndUserId = getCookie('optimizelyEndUserId') ? true : false;
	// Initialize the Optimizely variable
	window.optimizely = window.optimizely || [];
	// Prevent events from being send to Optimizely if the data sharing cookie is not set
	if (optimizelyAllowDataSharing && optimizelyEndUserId ) {
		window.optimizely.push({
			type: 'sendEvents'
		});
	} else {
		window.optimizely.push({
			type: 'holdEvents'
		});
	}
	/* eslint-disable */
}

const cookieOverlay = document.querySelector('.cookie-overlay');

if (!getCookie('cookieAccept') && cookieOverlay != null) {
	const cookieBanner = document.querySelector('.cookie-banner');
	const cookiePrimary = document.querySelector('.cookie-primary');
	const cookieSecondary = document.querySelector('.cookie-secondary');
	const cookieStep2 = document.querySelector('.cookie-step2');
	const cookieCancel = document.querySelector('.cookie-cancel');
	const cookieSave = document.querySelector('.cookie-save');

	cookieOverlay.style.display = 'block';
	cookieBanner.style.display = 'flex';

	// Set necessaryCookies to be disabled
	const necessaryInput = document.querySelector('[data-cookie-value="necessaryCookie"]');

	if (necessaryInput != null) {
		const necessaryInputParent = necessaryInput.closest('.checkbox');
		necessaryInputParent.classList.add('checkbox--inactive');
		necessaryInput.checked = true;
	}

	// Accept all cookies
	document.querySelector('.cookie-accept-all').addEventListener('click', function(e) {
		e.preventDefault();

		// Get all cookies
		const allCookies = document.getElementsByClassName('cookie-switch');
		const optimizelyEndUserId = getCookie('optimizelyEndUserId') ? true : false;
		if (allCookies.length > 0) {
			let i;
			for (i = 0; i < allCookies.length; i++) {
				setCookie(allCookies[i].dataset.cookieValue, 'true', 365);
			}
			if (optimizelyEndUserId) {
				setCookie('optimizelyAllowDataSharing', 'true', 365);
            }
		} else {
			setCookie('necessaryCookie', 'true', 365);
			setCookie('analyticalCookie', 'true', 365);
			setCookie('marketingCookie', 'true', 365);
			if (optimizelyEndUserId) {
				setCookie('optimizelyAllowDataSharing', 'true', 365);
			}
		}
		setCookie('cookieAccept', 'true', 365);

		// function to set/hold optimisely event.
		optimizelySendHoldEvent();

		/* eslint-disable */
		// Push cookie choices to GTM
		dataLayer.push({
			'event': 'cookieConsent',
			'consentMarketing': true,
			'consentAnalytics': true
		});
		/* eslint-enable */

		cookieOverlay.style.display = 'none';
		cookieBanner.style.display = 'none';
	});

	if (cookieStep2 != null) {
		cookieStep2.addEventListener('click', function(e) {
			e.preventDefault();
			cookiePrimary.style.display = 'none';
			cookieSecondary.style.display = 'block';
		});
	}

	if (cookieCancel != null) {
		cookieCancel.addEventListener('click', function(e) {
			e.preventDefault();
			cookiePrimary.style.display = 'block';
			cookieSecondary.style.display = 'none';
		});
	}

	if (cookieSave != null) {
		cookieSave.addEventListener('click', function(e) {
			e.preventDefault();

			// Get all selected cookies
			const allCookies = document.getElementsByClassName('cookie-switch');

			if (allCookies.length > 0) {
				let i;
				for (i = 0; i < allCookies.length; i++) {
					if (allCookies[i].checked === true) {
						// Only set the cookies that have been selected
						setCookie(allCookies[i].dataset.cookieValue, 'true', 365);
					}
				}
			}

			/* eslint-disable */
			const analyticalCookieSave = getCookie('analyticalCookie') ? true : false;
			const marketingCookieSave = getCookie('marketingCookie') ? true : false;
			const optimizelyEndUserId = getCookie('optimizelyEndUserId') ? true : false;

			if (optimizelyEndUserId && analyticalCookieSave) {
				setCookie('optimizelyAllowDataSharing', 'true', 365);
				// function to set/hold optimisely event.
				optimizelySendHoldEvent();
			}

			// Push cookie choices to GTM
			dataLayer.push({
				'event': 'cookieConsent',
				'consentMarketing': marketingCookieSave,
				'consentAnalytics': analyticalCookieSave
			});
			/* eslint-enable */

			setCookie('cookieAccept', 'true', 365);

			cookieOverlay.style.display = 'none';
			cookieBanner.style.display = 'none';
		});
	}
};
