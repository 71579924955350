// Changing tabs hide/show content
const quote = document.querySelectorAll('.quote');

if (quote.length > 0) {
	let i;
	for (i = 0; i < quote.length; i++) {
		screenResize(quote[i]);
	}
}

function screenResize(quoteContainer) {
	const w = document.documentElement.clientWidth;

	if (w <= 1024 && w >= 720) {
		const quoteString = quoteContainer.querySelector('.quote__text');
		const quoteLength = quoteString.textContent.length;

		if (quoteLength >= 100) {
			quoteContainer.classList.add('quote--reduced');
		}
	} else {
		quoteContainer.classList.remove('quote--reduced');
	}
}
