// Open share lightbox when share buttons are clicked if one exists on the page
const shareLightbox = document.querySelector('.lightbox--share');
const shareLightboxOverlay = document.querySelector('.overlay--lightbox');
const shareButtons = document.querySelectorAll('.js-share-link');

if (shareLightbox != null && shareButtons.length > 0) {
	let i;
	for (i = 0; i < shareButtons.length; i++) {
		shareButtons[i].addEventListener('click', function(e) {
			e.preventDefault();
			const shareLightboxInner = shareLightbox.querySelector('.lightbox-inner');
			const shareLightboxInnerMarkupTemplate = shareLightbox.querySelector('.lightbox-inner').innerHTML;
			let shareLightboxInnerMarkup = shareLightbox.querySelector('.lightbox-inner').innerHTML;
			// Variables that hold the information to be shared
			const shareURL = this.dataset.link;
			const shareMessage = this.dataset.message;

			// Replace variables in share lightbox markup with relevant share URL and message
			const newLightbox = document.createElement('div');
			newLightbox.classList.add('lightbox-inner');
			shareLightboxInnerMarkup = shareLightboxInnerMarkup.replaceAll('{SHARE_URL}', shareURL);
			shareLightboxInnerMarkup = shareLightboxInnerMarkup.replaceAll('{SHARE_MESSAGE}', shareMessage);
			newLightbox.innerHTML = shareLightboxInnerMarkup;
			shareLightbox.replaceChild(newLightbox, shareLightboxInner, function() {
				/*eslint-disable */
				FB.XFBML.parse(); // Re-initialise Facebook
				twttr.widgets.load(document.getElementById("twitterShare")); // Re-initialise Twitter
				/* eslint-enable */
			});

			// Show lightbox and overlay
			shareLightbox.classList.add('lightbox--visible');
			shareLightboxOverlay.classList.add('overlay--lightbox--visible');

			// Close lightbox button
			const lightboxClose = document.querySelector('.lightbox-close');

			// Close lightbox
			lightboxClose.addEventListener('click', function(e) {
				e.preventDefault();
				const currentShareLightbox = document.querySelector('.lightbox--share .lightbox-inner');
				// Create new node containing base lightbox
				const baseLightbox = document.createElement('div');
				baseLightbox.classList.add('lightbox-inner');
				baseLightbox.innerHTML = shareLightboxInnerMarkupTemplate;
				// Close current lightbox and overlay
				this.parentNode.parentNode.classList.remove('lightbox--visible');
				shareLightboxOverlay.classList.remove('overlay--lightbox--visible');
				// Restore lightbox to default position
				shareLightbox.replaceChild(baseLightbox, currentShareLightbox);
			});

			// Copy data attribute on same link
			const copyAttribute = document.querySelector('.js-copy-attr');

			if (copyAttribute != null) {
				copyAttribute.addEventListener('click', function(e) {
					e.preventDefault();
					// Create new element
					const el = document.createElement('textarea');
					// Set value (string to be copied)
					el.value = shareURL;
					// Set non-editable to avoid focus and move outside of view
					el.setAttribute('readonly', '');
					el.style = { position: 'absolute', left: '-9999px' };
					document.body.appendChild(el);
					// Select text inside element
					el.select();
					// Copy text to clipboard
					document.execCommand('copy');
					// Remove temporary element
					document.body.removeChild(el);
				});
			}
		});
	}
}
