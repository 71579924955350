function heroResize() {
	const w = document.documentElement.clientWidth;
	let i;
	const videos = document.getElementsByTagName('video');

	if (w >= 1024) {
		for (i = 0; i < videos.length; i++) {
			videos[i].setAttribute('autoplay', '');
		}
	} else {
		for (i = 0; i < videos.length; i++) {
			videos[i].removeAttribute('autoplay');
			videos[i].pause();
		}
	}
}

heroResize();
// Attaching the event listener function to window's resize event
window.addEventListener('resize', heroResize);
