// Clicking on thumbnails to control main image
const imageContainer = document.querySelector('.js-thumbnail-select-image');

if (imageContainer != null) {
	const primaryImage = imageContainer.querySelector('.js-primary-image');
	const thumbnailLinks = imageContainer.querySelectorAll('.thumbnail-link');

	let i;
	for (i = 0; i < thumbnailLinks.length; i++) {
		thumbnailLinks[i].addEventListener('click', function(e) {
			e.preventDefault();
			const dataImg = this.querySelector('.image__src').dataset.img;
			const currentActiveImage = imageContainer.querySelector('.thumbnail-link--active');
			// Set selected image to be active, replace primary message image source with selected image source
			currentActiveImage.classList.remove('thumbnail-link--active');
			this.classList.add('thumbnail-link--active');
			primaryImage.src = dataImg;
		});
	}
}
