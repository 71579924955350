const paymentappleButton = document.getElementById('applepay-btn-submit');
const paymentgpayButton = document.getElementById('gpay-btn-submit');
const walletpaymentnotification = document.getElementById('walletpaymentnotification-banner');
const walletpaymentnotificationBanner = document.querySelectorAll('.walletpaymentnotification-banner');

if (paymentappleButton != null) {
	paymentappleButton.onclick = function(e) {
		walletpaymentnotification.parentNode.classList.add('show');
		e.preventDefault();

		// DataLayer push to track behaviour of wallet payment buttons
		// Apple pay
		/* eslint-disable */
		if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
			dataLayer.push({
				'event': 'checkout_button_test',
				'event_category': 'navigation',
				'event_action': 'call to action',
				'event_label': 'apple pay'
			})
		}
		/* eslint-enable */
	};
}
if (paymentgpayButton != null) {
	paymentgpayButton.onclick = function(e) {
		walletpaymentnotification.parentNode.classList.add('show');
		e.preventDefault();

		// DataLayer push to track behaviour of wallet payment buttons
		// Google pay
		/* eslint-disable */
		if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
			dataLayer.push({
				'event': 'checkout_button_test',
				'event_category': 'navigation',
				'event_action': 'call to action',
				'event_label': 'google pay'
			})
		}
	};
}

if (walletpaymentnotificationBanner != null) {
	let i;
	for (i = 0; i < walletpaymentnotificationBanner.length; i++) {
		const notficiationBannerCloseDesktop = walletpaymentnotificationBanner[i].querySelector('.notification-banner__close-desktop');
		const notficiationBannerCloseMobile = walletpaymentnotificationBanner[i].querySelector('.notification-banner__close-mobile');

		// Close associated notification banner
		notficiationBannerCloseDesktop.addEventListener('click', function(e) {
			e.preventDefault();
			const openBanners = document.querySelectorAll('.walletpaymentnotification-banner');
			// Hide all payment dialogs
			let i;
			for (i = 0; i < openBanners.length; i++) {
				openBanners[i].parentNode.classList.remove('show');
			}
			if (this.closest('.payment-error-overlay') != null) {
				this.parentNode.parentNode.classList.remove('show');
			} else {
				this.parentNode.classList.remove('show');
			}
		});

		// Close associated notification banner
		notficiationBannerCloseMobile.addEventListener('click', function(e) {
			e.preventDefault();
			const openBannersMobile = document.querySelectorAll('.walletpaymentnotification-banner');
			// Hide all payment dialogs
			let i;
			for (i = 0; i < openBannersMobile.length; i++) {
				openBannersMobile[i].parentNode.classList.remove('show');
			}
			if (this.closest('.payment-error-overlay') != null) {
				this.parentNode.parentNode.classList.remove('show');
			} else {
				this.parentNode.classList.remove('show');
			}
		});
	}
}
