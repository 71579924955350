// JS for Forms
// Reveal password on click of 'reveal' icon
const revealIconContainer = document.querySelectorAll('.js-hide-show');
const focusInstruction = document.querySelectorAll('.js-focus-instruction input');
const textInstruction = document.querySelectorAll('.text-field__instruction');
const textFieldContainer = document.querySelectorAll('.text-field');

// Show/hide contents of password field when you click on eye icon
if (revealIconContainer.length > 0) {
	let i;
	for (i = 0; i < revealIconContainer.length; i++) {
		revealIconContainer[i].addEventListener('click', function(e) {
			e.preventDefault();
			e.stopPropagation();

			const currentInputParent = this.parentNode;
			const currentInput = currentInputParent.getElementsByTagName('input')[0];
			const hideIcon = this.parentNode.querySelector('.text-field__icon--hide');
			const showIcon = this.parentNode.querySelector('.text-field__icon--show');

			// Toggle between hiding and showing the password by changing the input type
			if (currentInput.type === 'password') {
				currentInput.type = 'text';
				showIcon.classList.add('hidden');
				hideIcon.classList.remove('hidden');
			} else {
				currentInput.type = 'password';
				showIcon.classList.remove('hidden');
				hideIcon.classList.add('hidden');
			}
		});
	}
}

// Show/hide instruction message when input is focused
if (focusInstruction.length > 0) {
	let i;
	for (i = 0; i < focusInstruction.length; i++) {
		const instructionMsg = focusInstruction[i].getAttribute('data-instruction-msg');
		// Show instruction message on focus
		focusInstruction[i].addEventListener('focus', function(e) {
			this.closest('.form__input-container').querySelector('.text-field__instruction').classList.remove('hidden');
			if (instructionMsg != null) {
				this.closest('.js-add-error-markup').querySelector('.text-field__instruction .custom-instruction').innerHTML = instructionMsg;
			}
		});
	}
}
// Hide instruction msg when clicked on document
document.onclick = function() {
	if (textInstruction.length > 0) {
		let i;
		for (i = 0; i < textInstruction.length; i++) {
			textInstruction[i].classList.add('hidden');
		}
	}
};
if (textFieldContainer.length > 0) {
	let i;
	for (i = 0; i < textFieldContainer.length; i++) {
		textFieldContainer[i].onclick = function(e) {
			e.stopPropagation();
		};
	}
}
