// Remove spaces functions
const removeSpacesPaste = function(e) {
	e.preventDefault();
	let noSpaceResult = '';
	noSpaceResult = e.clipboardData.getData('Text');
	noSpaceResult = noSpaceResult.replace(/ /g, '');
	this.value = noSpaceResult;
};

const removeSpacesKeyup = function(e) {
	e.preventDefault();
	let noSpaceResult = this.value;
	noSpaceResult = noSpaceResult.replace(/ /g, '');
	this.value = noSpaceResult;
};

const inputPreventSpaces = document.querySelectorAll('.input-no-spaces');

if (inputPreventSpaces.length > 0) {
	let i;
	for (i = 0; i < inputPreventSpaces.length; i++) {
		inputPreventSpaces[i].onpaste = removeSpacesPaste;
		inputPreventSpaces[i].onkeyup = removeSpacesKeyup;
	}
}

// Validate IBAN structure - a space is automatically added after every 4 characters
const ibanInputs = document.querySelectorAll('.iban-validate');

const addSpaceEveryFour = function(e) {
	e.preventDefault();
	const target = e.target;
	let position = target.selectionEnd;
	const length = target.value.length;

	target.value = target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim(); // Add a space every 4 chars
	target.selectionEnd = position += ((target.value.charAt(position - 1) === ' ' && target.value.charAt(length - 1) === ' ' && length !== target.value.length) ? 1 : 0); // Keep the position of the cursor if you edit a character in the middle
};

const addSpaceEveryFourPaste = function(e) {
	e.preventDefault();
	const target = e.target;

	target.value = e.clipboardData.getData('Text').replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim(); // Add a space every 4 chars
};

if (ibanInputs.length > 0) {
	let i;
	for (i = 0; i < ibanInputs.length; i++) {
		ibanInputs[i].onpaste = addSpaceEveryFourPaste;
		ibanInputs[i].onkeyup = addSpaceEveryFour;
	}
}
