// JS for site-wide Product Configuration Widget
const productConfigWidgetFull = document.querySelectorAll('.js-product-config');
const jsToggleDonationAmountsControl = document.querySelectorAll('.js-toggle-donation-amounts-control');

// Hide / show different monthly/one-off donation amounts
if (jsToggleDonationAmountsControl != null) {
	let n;
	for (n = 0; n < jsToggleDonationAmountsControl.length; n++) {
		const jsToggleDonationAmountswrapper = jsToggleDonationAmountsControl[n];
		const controlTabs = jsToggleDonationAmountsControl[n].querySelectorAll('input[type="radio"]');
		const showHideDonationAmounts = (thiselement) => {
			const activeTab = thiselement.querySelector('input[type="radio"]:checked');
			const controlSetsWrapper = thiselement.closest('.productconfig-wrapper');
			const controlSets = controlSetsWrapper.querySelectorAll('.js-toggle-donation-amounts-set');
			const activeTabControl = activeTab.id;
			let i;
			for (i = 0; i < controlSets.length; i++) {
				controlSets[i].classList.add('js-hidden');
			}
			const activeDonationAmountsSet = controlSetsWrapper.querySelector("[data-control='" + activeTabControl + "']");
			activeDonationAmountsSet.classList.remove('js-hidden');
			const activeDonationAmount = activeTab.dataset.defaultAmount;
			document.getElementById('MinDonationAmount').value = activeTab.dataset.minAmount;
			// Check if URL contains query string parameters as this overrides default donation amount
			const queryString = window.location.search;
			let activeDonationAmountInput;
			if (queryString.includes('donationAmount')) {
				const urlParams = new URLSearchParams(queryString);
				const donationAmount = urlParams.get('donationAmount');
				// Set donation amount to be amount shown in URL, or if blank show default amount
				if (donationAmount.length > 0) {
					activeDonationAmountInput = document.querySelector("input[value='" + donationAmount + "']");
				} else {
					activeDonationAmountInput = activeDonationAmountsSet.querySelector("input[value='" + activeDonationAmount + "']");
				}
			} else { // If no donation amount in URL, select donation amount using cookies
				const selectedValue = activeDonationAmountsSet.querySelector("input[value='" + activeDonationAmount + "']");
				if (selectedValue != null) {
					activeDonationAmountInput = activeDonationAmountsSet.querySelector("input[value='" + activeDonationAmount + "']");
				} else {
					// Get cookie value for donation amount
					const donationAmountCookie = document.cookie.match(new RegExp('(^| )' + 'wv_donationAmount' + '=([^;]+)'));
					activeDonationAmountInput = activeDonationAmountsSet.querySelector("input[value='" + donationAmountCookie[2] + "']");
				}
			}

			// Default behaviour: use default donation amount and frequency set in Umbraco
			const activeDonationAmountText = activeDonationAmountInput.dataset.amountText;
			const activeTabChipText = activeDonationAmountsSet.querySelector('.chip-text');

			activeTabChipText.style.visibility = 'visible';
			activeTabChipText.innerText = activeDonationAmountText;
		};
		showHideDonationAmounts(jsToggleDonationAmountswrapper);
		let i;
		for (i = 0; i < controlTabs.length; i++) {
			const thisControl = controlTabs[i];
			thisControl.addEventListener('click', function() {
				showHideDonationAmounts(jsToggleDonationAmountswrapper);
			});
		}
	}
}

if (productConfigWidgetFull != null) {
	let k;
	for (k = 0; k < productConfigWidgetFull.length; k++) {
		let captionInput = productConfigWidgetFull[k].querySelectorAll('.chip-set-container:not(.js-hidden) .chip-input');
		const productConfigCaptionChips = productConfigWidgetFull[k].querySelectorAll('.chip-set--caption .radio-button--chip');
		const donationAmountInputs = productConfigWidgetFull[k].querySelectorAll('.js-toggle-donation-amounts-control input[type="radio"]');
		const productConfigInput = productConfigWidgetFull[k].querySelector('.js-product-config-manual');
		let captionInputValues = [];
		const productConfigForm = productConfigWidgetFull[k].querySelector('.transaction-form');
		const childIDInput = productConfigWidgetFull[k].querySelector('.js-child-id-validate');

		// Add caption input values to donation values array
		if (captionInput.length > 0) {
			let i;
			for (i = 0; i < captionInput.length; i++) {
				captionInputValues.push(captionInput[i].value);
			}
		}

		// Add click event to donation amount inputs

		if (donationAmountInputs.length > 0) {
			let i;
			for (i = 0; i < donationAmountInputs.length; i++) {
				if (donationAmountInputs[i].checked === true) {
					const checkedChipText = donationAmountInputs[i].dataset.amountText;
					// const chipTextContainer = donationAmountInputs[i].closest('.chip-set-container').querySelector('.chip-text');
					const checkedChipId = donationAmountInputs[i].id;
					const controlSetsWrapper = donationAmountInputs[i].closest('.productconfig-wrapper');
					const checkedChipContainer = controlSetsWrapper.querySelector("[data-control='" + checkedChipId + "']");
					const chipTextContainer = checkedChipContainer.querySelector('.chip-text');
					chipTextContainer.style.visibility = 'visible';
					// Set chip information text
					if (chipTextContainer != null) {
						chipTextContainer.innerHTML = checkedChipText;
					}
				}
				donationAmountInputs[i].addEventListener('click', (event) => {
					const thisElement = event.target;
					const thisDefaultAmount = thisElement.dataset.defaultAmount;
					const defaultSelectedInput = thisElement.closest('.js-product-config').querySelector('.chip-set-container:not(.js-hidden) input[value="' + thisDefaultAmount + '"]');
					const checkedChipText = thisElement.dataset.amountText;
					const chipTextContainer = thisElement.closest('.productconfig-wrapper').querySelector('.chip-text');
					chipTextContainer.style.visibility = 'visible';
					// Set chip information text
					if (chipTextContainer != null) {
						chipTextContainer.innerHTML = checkedChipText;
					}
					// Check the relevant input
					if (defaultSelectedInput != null) {
						defaultSelectedInput.checked = true;
					}
					selectCaptionChipProductConfig(event);
				});
			}
		}

		// Add click event to product config chips
		if (productConfigCaptionChips.length > 0) {
			let i;
			for (i = 0; i < productConfigCaptionChips.length; i++) {
				productConfigCaptionChips[i].addEventListener('click', (event) => {
					selectCaptionChipProductConfig(event);
				});
			}
		}

		// Get Cookie function
		function getCookie(cname) {
			const name = cname + '=';
			const ca = document.cookie.split(';');
			for (let i = 0; i < ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) === ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) === 0) {
					return c.substring(name.length, c.length);
				}
			}
			return '';
		}

		// Function to Show or hide error message
		const showHideError = (manualDonationValue, manualInputLabel, errorMessage, errorMessageMax, chipTextContainer) => {
			const productconfigcontainer = chipTextContainer.closest('.js-product-config');
			const paymentFreqInput = productconfigcontainer.querySelector('.js-toggle-donation-amounts-control input:checked');
			const errorMessageText = productconfigcontainer.querySelector('#manual-error .input__msg-text');
			const errorMessageMaxText = productconfigcontainer.querySelector('#manual-error-max .input__msg-text');

			// Check min amount
			let paymentFreqInputMinAmount = 0;
			let paymentFreqInputMaxAmount = 0;

			// If no min amount is set by product config, use min amount set on manual input
			if (paymentFreqInput != null) {
				paymentFreqInputMinAmount = parseInt(paymentFreqInput.dataset.minAmount);
				errorMessageText.innerText = paymentFreqInput.dataset.minError;
				paymentFreqInputMaxAmount = parseInt(paymentFreqInput.dataset.maxAmount);
				errorMessageMaxText.innerText = paymentFreqInput.dataset.maxError;
			} else {
				paymentFreqInputMinAmount = parseInt(productConfigInput.dataset.minDonation);
				paymentFreqInputMaxAmount = parseInt(productConfigInput.dataset.maxDonation);
			}

			if (manualDonationValue < paymentFreqInputMinAmount) {
				manualInputLabel.classList.add('text-field--error');
				errorMessage.classList.remove('js-hidden');
			} else {
				errorMessage.classList.add('js-hidden');
				if (paymentFreqInputMaxAmount > 0 && manualDonationValue > paymentFreqInputMaxAmount) {
					manualInputLabel.classList.add('text-field--error');
					errorMessageMax.classList.remove('js-hidden');
				} else {
					manualInputLabel.classList.remove('text-field--error');
					errorMessageMax.classList.add('js-hidden');
					chipTextContainer.innerHTML = '';
				}
			}
		};

		// Highlight donation chip if manual input is the same as chip value, validate manual input based on minimum amount
		if (productConfigCaptionChips.length > 0) {
			const manualInputLabel = productConfigWidgetFull[k].querySelector('.text-field--currency');
			const errorMessage = productConfigWidgetFull[k].querySelector('#manual-error');
			const errorMessageMax = productConfigWidgetFull[k].querySelector('#manual-error-max');
			const chipTextContainer = productConfigWidgetFull[k].querySelector('.chip-set-container:not(.js-hidden) .chip-text');
			const currentSelectedValue = productConfigWidgetFull[k].querySelector('.js-toggle-donation-amounts-set input:checked');

			// On page load validate cookie value if it exists
			var cookieDonationAmount = getCookie('wv_donationAmount');
			if (cookieDonationAmount || productConfigInput.classList.contains('input-validation-error')) {
				cookieDonationAmount = cookieDonationAmount === '' ? 0 : parseInt(cookieDonationAmount);
				showHideError(cookieDonationAmount, manualInputLabel, errorMessage, errorMessageMax, chipTextContainer);
			}

			// Validate any manual input value
			productConfigInput.addEventListener('keyup', function(e) {
				const manualDonationValue = this.value === '' ? 0 : parseInt(this.value);

				// Hide any associated chip text if the value of the input is 0
				if (currentSelectedValue != null) {
					this.closest('.transaction-form').querySelector('.chip-set-container:not(.js-hidden) .chip-text').innerHTML = '';
				}

				// See if typed value exists in donations array
				if (captionInputValues.indexOf(this.value) > -1) {
					const typedInput = this.closest('.transaction-form').querySelector('.chip-set-container:not(.js-hidden) input[value="' + manualDonationValue + '"]');

					// Uncheck all other inputs
					if (captionInput.length > 0) {
						let i;
						for (i = 0; i < captionInput.length; i++) {
							captionInput[i].checked = false;
						}
					}
					showHideError(manualDonationValue, manualInputLabel, errorMessage, errorMessageMax, chipTextContainer);
					// Check the relevant input
					typedInput.checked = true;
					selectCaptionChipProductConfig();
				} else {
					showHideError(manualDonationValue, manualInputLabel, errorMessage, errorMessageMax, chipTextContainer);

					// Uncheck all other inputs
					if (captionInput.length > 0) {
						let i;
						for (i = 0; i < captionInput.length; i++) {
							captionInput[i].checked = false;
						}
					}

					if (currentSelectedValue != null) {
						// Remove selected class
						currentSelectedValue.checked = false;
						// Hide donation text
						chipTextContainer.innerHTML = '';
					}
				}
			});
		}

		if (captionInput.length > 0) {
			if (parseInt(productConfigInput.value) >= parseInt(productConfigInput.dataset.minDonation)) {
				const manualInputLabel = productConfigWidgetFull[k].querySelector('.text-field--currency');
				const errorMessages = productConfigWidgetFull[k].querySelectorAll('.input__msg--error');
				// Remove any error state
				manualInputLabel.classList.remove('text-field--error');
				productConfigInput.classList.remove('input-validation-error');
				let j;
				for (j = 0; j < errorMessages.length; j++) {
					errorMessages[j].classList.add('js-hidden');
				}
			}
			let i;
			for (i = 0; i < captionInput.length; i++) {
				if (captionInput[i].checked === true && captionInput[i].value === productConfigInput.value) {
					const checkedChipText = captionInput[i].dataset.amountText;
					const chipTextContainer = captionInput[i].closest('.chip-set-container').querySelector('.chip-text');
					chipTextContainer.style.display = 'block';

					// Set chip information text
					if (chipTextContainer != null) {
						chipTextContainer.innerHTML = checkedChipText;
					}
				} else {
					captionInput[i].checked = false;
				}
			}
		}

		// Select caption function
		function selectCaptionChipProductConfig(event) {
			const newproductConfigWidgetFull = event.target.closest('.js-product-config');
			captionInput = newproductConfigWidgetFull.querySelectorAll('.chip-set-container:not(.js-hidden) .chip-input');
			captionInputValues = [];
			// Add caption input values to donation values array
			if (captionInput.length > 0) {
				let i;
				for (i = 0; i < captionInput.length; i++) {
					captionInputValues.push(captionInput[i].value);
				}
			}

			const manualInputLabel = newproductConfigWidgetFull.querySelector('.text-field--currency');
			const errorMessages = newproductConfigWidgetFull.querySelectorAll('.input__msg--error');

			if (captionInput.length > 0) {
				let i;
				for (i = 0; i < captionInput.length; i++) {
					if (captionInput[i].checked === true) {
						const checkedChipText = captionInput[i].dataset.amountText;
						const checkedChipValue = captionInput[i].value;
						const chipTextContainer = captionInput[i].closest('.chip-set-container').querySelector('.chip-text');

						// Remove any error state
						manualInputLabel.classList.remove('text-field--error');
						productConfigInput.classList.remove('input-validation-error');
						let j;
						for (j = 0; j < errorMessages.length; j++) {
							errorMessages[j].classList.add('js-hidden');
						}
						productConfigInput.value = checkedChipValue;
						chipTextContainer.style.display = 'block';

						// Set chip information text
						if (chipTextContainer != null) {
							chipTextContainer.innerHTML = checkedChipText;
						}
					} else {
						captionInput[i].checked = false;
					}
				}
			}
		}

		// Function to check child ID against regex
		const checkChildID = function(childID, input, event) {
			const childIDRegex = /^([A-Z]{3}[-][0-9]{6}[-][0-9]{4})$/;
			const parentDiv = input.closest('.form__input-container');
			const inputLabel = input.closest('.text-field');
			const inputError = parentDiv.querySelector('.input__msg');
			if (childIDRegex.test(childID) === false) {
				// If child ID input value does not match regex
				inputError.classList.remove('js-hidden');
				inputError.classList.remove('field-validation-valid');
				inputError.classList.add('field-validation-invalid');
				inputLabel.classList.add('text-field--error');
				event.preventDefault();
			} else { // If child ID input value does match regex
				inputError.classList.remove('field-validation-invalid');
				inputError.classList.add('field-validation-valid');
				inputLabel.classList.remove('text-field--error');
			}
		};

		// Custom validation for when form is submitted
		if (productConfigForm != null) {
			productConfigForm.addEventListener('submit', function(event) {
				// Validation on Child ID input field - 'XXX-123456-1234' (hyphens required)
				if (childIDInput != null) {
					checkChildID(childIDInput.value, childIDInput, event);
				}
			});
		}
	}
}
