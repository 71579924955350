const errorMsgBlock = document.querySelectorAll('.donation-amount-error-block input');
const errorMsgSubmitButton = document.querySelectorAll('.donation-amount-error-block button');
const inputChip = document.querySelectorAll('.radio-button');

// Display error msg on submit
if (errorMsgSubmitButton.length > 0) {
	let i;
	for (i = 0; i < errorMsgSubmitButton.length; i++) {
		errorMsgSubmitButton[i].onclick = function(e) {
			if (errorMsgBlock.length > 0) {
				let i;
				for (i = 0; i < errorMsgBlock.length; i++) {
					const minInputDonationAmount = errorMsgBlock[i].getAttribute('data-min-donation');
					const donationAmountErrorMsg = errorMsgBlock[i].getAttribute('data-min-error');
					if (errorMsgBlock[i].value < Math.floor(minInputDonationAmount)) {
						// Display error msg
						this.closest('.js-add-error-markup').querySelector('.donation-amount-error-msg').classList.remove('hidden');
						// Display input field as error
						this.closest('.js-add-error-markup').querySelector('.text-field__icon-container').classList.add('input-error');
						// hide instruction msg
						this.closest('.js-add-error-markup').querySelector('.donation-amount-instruction-msg').classList.add('hidden');
						// on focus
						errorMsgBlock[i].addEventListener('focus', function(e) {
							document.querySelector('.text-field--currency').classList.remove('text-field--error');
							this.closest('.js-add-error-markup').querySelector('.text-field__icon-container').classList.remove('input-error');
							this.closest('.js-add-error-markup').querySelector('.donation-amount-error-msg').classList.add('hidden');
							this.closest('.js-add-error-markup').querySelector('.donation-amount-instruction-msg').classList.remove('hidden');
						});
						if (donationAmountErrorMsg != null) {
							this.closest('.js-add-error-markup').querySelector('.donation-amount-error-msg .custom-error').innerHTML = donationAmountErrorMsg;
						}
						e.preventDefault();
					}
				}
			}
		};
	}
}

if (errorMsgBlock.length > 0) {
	// Hide error styles
	document.querySelector('.donation-amount-error-block input').addEventListener('keyup', function(e) {
		const minInputDonationAmount = document.querySelector('.donation-amount-error-block input').getAttribute('data-min-donation');
		if (document.querySelector('.donation-amount-error-block input').value === '') {
			document.querySelector('.donation-amount-error-block .text-field--currency').classList.remove('text-field--error');
			document.querySelector('.donation-amount-error-msg').classList.add('hidden');
		}
		if (document.querySelector('.donation-amount-error-block input').value < Math.floor(minInputDonationAmount)) {
			document.querySelector('.donation-amount-error-block .text-field--currency').classList.remove('text-field--error');
		}
	});
}

if (inputChip.length > 0) {
	let i;
	for (i = 0; i < inputChip.length; i++) {
		inputChip[i].onclick = function(e) {
			// Remove error when click on radio buttons
			document.querySelector('.text-field__icon-container').classList.remove('input-error');
			if (document.querySelector('.donation-amount-error-msg') != null) {
				document.querySelector('.donation-amount-error-msg').classList.add('hidden');
			}
		};
	}
}
