const copyButton = document.getElementById('copy-account-number');
if (copyButton != null) {
	copyButton.onclick = function(e) {
		const copyText = document.getElementById('copy-account-number').getAttribute('data-text');
		const textarea = document.createElement('textarea');
		textarea.textContent = copyText;
		textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
		document.body.appendChild(textarea);
		textarea.select();
		document.execCommand('copy');
		document.body.removeChild(textarea);
		e.preventDefault();
	};
}
