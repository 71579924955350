// Count words on a page if
const wordcountEl = document.querySelectorAll('.js-wordcount');

// strip out script tags
function noscript(strCode) {
	return strCode.replace(/<script.*?>.*?<\/script>/igm, '');
}

if (wordcountEl.length > 0) {
	const body = document.body;
	const readingTimeEl = document.querySelector('.js-readingTime');
	if (body) {
		let words = document.body[('innerText' in document.body) ? 'innerText' : 'textContent'];
		words = noscript(words);
		words = words.match(/\S+/g).length;

		// Calculate page reading time in minutes
		let readingTime = words / 200;
		readingTime = Math.ceil(readingTime);

		readingTimeEl.innerText = readingTime;
	}
}
