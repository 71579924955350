// Tabs are controlled by a select dropdown
const dropdownTabContainer = document.querySelector('.dropdown-tab__container');
const openError = document.querySelector('.payment-notification.notification-banner');

if (dropdownTabContainer != null) {
	const dropdownTabs = dropdownTabContainer.querySelectorAll('.dropdown-content__tab');
	const tabSelect = dropdownTabContainer.querySelector('.select-selected');
	// Make first tab active on page load
	let i;
	for (i = 0; i < dropdownTabs.length; i++) {
		dropdownTabs[0].classList.add('dropdown-content__tab--active');
	}

	const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
	const observer = new MutationObserver(showHideTabs); // Run function to show/hide tabs whenever tabSelect is changed
	observer.observe(tabSelect, { // Listen to changes on tabSelect
		childList: true
	});

	// Show tab that corresponds to index of selected option
	function showHideTabs() {
		const selectedTabNumber = parseInt(tabSelect.dataset.tab);

		let i;
		for (i = 0; i < dropdownTabs.length; i++) {
			dropdownTabs[i].classList.remove('dropdown-content__tab--active');

			if (i === (selectedTabNumber - 1)) { // Subtract one from selected tab number to account for disabled option
				dropdownTabs[i].classList.add('dropdown-content__tab--active');
			}
		}
	}
}

// If there's an error message showing, show the corresponding tab
if (openError != null) {
	const dropdownTabs = dropdownTabContainer.querySelectorAll('.dropdown-content__tab');
	const tabSelect = dropdownTabContainer.querySelector('.select-selected');
	let errorID = openError.id;
	errorID = errorID.replace('-Dialog', '');
	const dropdownPaymentMethodOption = document.querySelectorAll('.dropdown--payment-method option');

	let i;
	for (i = 0; i < dropdownTabs.length; i++) { // Make tab associated with error message active
		dropdownTabs[i].classList.remove('dropdown-content__tab--active');

		if (dropdownTabs[i].getAttribute('data-tab-error-id') === errorID) { // Set relevant tab to be active, update data attribute
			const thisDropdownOption = dropdownPaymentMethodOption[i + 1]; // Add one to tab number to account for disabled option
			tabSelect.dataset.tab = i + 1;
			tabSelect.innerHTML = thisDropdownOption.text;
		}
	}
}
